import { render, staticRenderFns } from "./innerPage01.vue?vue&type=template&id=d2831c0a&scoped=true&"
import script from "./innerPage01.vue?vue&type=script&lang=js&"
export * from "./innerPage01.vue?vue&type=script&lang=js&"
import style0 from "./innerPage01.vue?vue&type=style&index=0&id=d2831c0a&prod&lang=less&"
import style1 from "./innerPage01.vue?vue&type=style&index=1&id=d2831c0a&prod&scoped=scoped&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2831c0a",
  null
  
)

export default component.exports